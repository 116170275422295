import React from 'react'
import { graphql } from 'gatsby'
import Layout from "../../components/layout"
import { Container, Row, Col } from 'reactstrap'
import Link from '../../components/link'
import BoxBorderless from '../../components/boxBorderless'
import styled from 'styled-components'
import Image from "gatsby-image"
import Breadcrumb  from "../../components/breadcrumb"
import useSiteMetadata from '../../hooks/use-site-config'
import { Helmet } from "react-helmet"

export const query = graphql`
query {
  HID: file(name: { eq: "Lector_HID_0" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 1200){
        ...GatsbyImageSharpFluid
      }
    }
  }
  ATHENA: file(relativePath: { eq: "assets/images/technical_support_images/Lector_ATHENA_0.jpg" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  iosIcon: file(relativePath: { eq: "assets/images/technical_support_images/t_s_apple_icon.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  EZ: file(relativePath: { eq: "assets/images/technical_support_images/Lector_EZ_0.jpg" }) {
    childImageSharp {
      fluid{
        ...GatsbyImageSharpFluid
      }
    }
  }
  DOWN: file(name: { eq: "download_icon" }) {
    childImageSharp {
      fluid{
        ...GatsbyImageSharpFluid
      }
    }
  }
  CARD: file(name: { eq: "card_icon" }) {
    childImageSharp {
      fluid{
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`


const DriverList = styled.div`
font-size: x-large;
margin-top: 3rem;
margin-bottom: -10px;
span{
  font-weight: 800;
}
`
const MacTitle = styled.h3`
margin-top: 2rem;
`

const AppleImage = styled(Image)`
  margin-top: 20px;
  display: block;
  margin-right: 0px;
  margin-left: 28px;
  max-width: 75px;
  max-height: 75px;
  
  `

const DriversImage = styled(Image)`
  height: 100px !important;
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  `

let Drivers = ({ link, image }) => (
  <Col lg="2" >
    <Link to={link} className="link">
      <BoxBorderless className="mt-0">
        <DriversImage fluid={image} imgStyle={{ objectFit: "contain", }} />
      </BoxBorderless>
    </Link>
  </Col>
)

const DownloadList= styled.ul`
list-style: none;
    font-size: small;
    color: #932a7e;
`
const Download= styled.a`
    font-size: small;
    color: #932a7e;
`


export default function MacOS(props) {

  const { siteTitle } = useSiteMetadata()
  const athenaIcon = props.data.ATHENA.childImageSharp.fluid
  const iosIcon = props.data.iosIcon.childImageSharp.fluid
  const ezIcon = props.data.EZ.childImageSharp.fluid
  const hidIcon = props.data.HID.childImageSharp.fluid
  const downloadIcon = props.data.DOWN.childImageSharp.fluid
  const cardIcon = props.data.CARD.childImageSharp.fluid
  const title= "Configuración Mac OS | " + siteTitle;

  return (
    <Layout>
           <Helmet title={title}>
          <meta charSet="utf-8" />
        </Helmet>
      <Container>
        <div className="mr-auto ml-auto col-8 col-sm-12  justify-content-center row">
          <AppleImage className="col-6" fluid={iosIcon} imgStyle={{ objectFit: "contain", textAlign: "center" }} />
          <MacTitle className="col-6" >Configuración Mac OS</MacTitle>
        </div>
        <Row className="justify-content-center" >
          <Col className="text-center">
            <DriverList><span>1.</span> Indentificar el lector de CIPE.</DriverList>
            <Row className="justify-content-center">
              <Drivers image={hidIcon} link={"/"} />
              <Drivers image={athenaIcon} link={"/"} />
              <Drivers image={ezIcon} link={"/"} />
            </Row>
            <DriverList><span>2.</span> Descargar el driver desde la página del fabricante.</DriverList>
            <Col lg="2 m-auto" >
                <BoxBorderless className="mt-0">
                  <DriversImage fluid={downloadIcon} imgStyle={{ objectFit: "contain", }} />
                </BoxBorderless>
            </Col>
            <div style={{ fontSize: "larger" }}>*En caso de que el lector no requiera instalación, saltear este paso.</div>
            <DriverList><span>3.</span> Descargar e instalar el autentificador de CIPE.</DriverList>
            <Col lg="2 m-auto" >
                <BoxBorderless className="mt-0">
                  <DriversImage fluid={cardIcon} imgStyle={{ objectFit: "contain", }} />
              </BoxBorderless>
              <Download href={"/static/lectores/GPSL_NK_CIPE30.zip"} download>Servicio de Autenticación con CIPE (IOS)</Download>
            </Col>
          </Col>
        </Row>
      </Container>
      <Breadcrumb pageContext={props.pageContext} label="Mac Os"/>
    </Layout>
  );
}
