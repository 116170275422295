import React from 'react'
import styled from "styled-components"

const BoxBorderless = styled.div`
 
  border-radius: 1em;
  padding: 1rem;
  color: #000000;
  transition-duration: 0.3s;
  text-align: center;
  margin-top: 2rem;


`

export default ({children, ...opts}) => (
  <BoxBorderless {...opts}>
    {children}
  </BoxBorderless>
)
